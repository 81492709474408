@import url(./fonts.css);

@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */


@layer utilities {
  .loginHeader {
    @apply flex items-center h-full pt-4 pb-2 text-lg border-b-4 border-transparent;
  }
  .dashboardHeader {
    @apply flex items-center h-full px-4 pt-2 pb-4 border-t-4 border-transparent;
  }

  .dashboard-header-active {
    @apply border-primaryColor text-primaryColor bg-primaryColor bg-opacity-10;
  }
  .login-header-active {
    @apply border-primaryColor text-primaryColor;
  }
  input {
    @apply p-1 border border-gray-800 !rounded-[4px] h-7;
  }
  .btn {
    @apply px-4 py-1 text-white !rounded-[4px] bg-primaryColor h-9;
  }
  select {
    @apply !rounded-[4px];
  }
  .btn-outline {
    @apply text-black bg-transparent border border-primaryColor;
  }

  .tooltip {
    @apply absolute right-0 z-50 p-2 transform translate-x-full bg-white w-36;
    z-index: 999999 !important;
  }

  .table-row:nth-child(even) {
    @apply bg-gray-100;
  }

  .padding-screen {
    @apply xl:px-20 lg:px-10 px-4;
  }
  .show-hide {
    @apply hidden lg:block;
  }

  .flex-collapse {
    @apply flex-col lg:flex-row items-start lg:items-center;
  }
}

body {
  font-family: "Prompt", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.hg-button {
  font-weight: 500 !important;
}
