@font-face {
    font-family: "Prompt";
    src: url(./fonts/Prompt/Prompt-Regular.ttf);
    font-weight: 500;
}
@font-face {
    font-family: "Prompt";
    src: url(./fonts/Prompt/Prompt-Bold.ttf);
    font-weight: 700;
}
@font-face {
    font-family: "Prompt";
    src: url(./fonts/Prompt/Prompt-SemiBold.ttf);
    font-weight: 600;
}
@font-face {
    font-family: "Prompt";
    src: url(./fonts/Prompt/Prompt-Light.ttf);
    font-weight: 400;
}